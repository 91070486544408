<template>
  <div
    :class="{ 
      'bd': true, 
      'pg': true, 
      'onnav': navbar, 
      'offnav': !navbar, 
      'scroll-locked': chat, 
      'scrolled': isScrolled 
    }"
    :theme="useNavbarTheme().value" 
    :data-section="getSection" 
    ref="app" 
    id="app"
    >

    <div class="observer"></div>

    <div class="topbar">
      <div class="back">
        <i class="icon-angle-left"></i>
        Back
      </div>
      <div class="logob">
        <img :src="useNavbarTheme().value == 'dark' ? '/logo/logo-dark.svg' : '/logo/logo-light.svg'" class="logo" alt="Growdiaries">
      </div>
      <div class="acts">
        <div class="btn outline" type="is-primary">Share</div>
      </div>
    </div>


    <Debug />

    <Popup />




    <div class="pc">
      <NuxtPage />
    </div>

  </div>


  <GeneralLightboxDiary v-if="useLightboxDiary().lightboxDiary" />
</template>



<script setup>

const $route = useRouter()
const chat = useChatOpened();
const navbar = useNavbarOpened();


const isScrolled = ref(false);
const observer = ref(null);



const getSection = computed(() => {
  var section = $route.currentRoute.value.path.split('/')[1];
  return section ? section : 'home';
});

const scrollHandler = (entries) => {
  // Если entries[0].isIntersecting == true, значит элемент видим, иначе скрыт
  isScrolled.value = !entries[0].isIntersecting;
};


onMounted(() => {
  // Создаём экземпляр IntersectionObserver
  observer.value = new IntersectionObserver(scrollHandler, {
    // Опции Observer
    root: null, // проверяем относительно viewport
    threshold: 0.1 // вызывать callback когда 10% элемента видимы
  });

  // Наблюдаем за элементом header, для этого он должен быть на странице
  const header = document.querySelector('.observer');
  if (header) observer.value.observe(header);
});

onUnmounted(() => {
  // Останавливаем наблюдение при размонтировании компонента
  if (observer.value) observer.value.disconnect();
});


</script>
 


<style scoped>
.pg {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  min-height: 100vh;
    height: 100vh;
    overflow-y: auto;
}

.pg>nav {
  flex-shrink: 0;
  width: 100%;
}

.pg>.pc {
  width: calc(100% - 0px);
  padding: 0 0rem;

  min-height: 100%;
  flex-direction: column;
  margin-left: 0;
}

.pg.onnav>.pc {
  width: calc(100% - 0px);

}



/* PB */

.pg,
.pg>.pc {
  min-height: 100%;
}

.pg .pc .pb {
  margin: 0px auto;
  width: calc(100% - 0px);
  padding-top: 1rem;
  padding-bottom: 1rem;
  container: pb / inline-size;
}

.pg .pc .pb.tabs_container {
  padding-top: 20px;
  padding-bottom: 0px;
}


/*  */

.topbar {
  width: 100%;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
}

.topbar .logob {
  width: 190px;
    margin: 0 auto;
}

.topbar .logob .logo {}

.topbar .back {
  font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 5px;
}
.topbar .back i{
  font-size: 1.2rem;
    margin-top: 3px;
}

.topbar .acts {}
</style>
